import GatsbyImage from 'gatsby-image';
import React from 'react';
import { KnowledgeData } from '@types';
import styles from './knowledge.module.scss';

type KnowledgeProps = {
  knowledge: KnowledgeData;
};

const Knowledge: React.FC<KnowledgeProps> = ({ knowledge }) => (
  <figure className={styles.knowledge}>
    <GatsbyImage
      className={styles.image}
      fluid={knowledge.image}
      alt={knowledge.title}
    />
    <figcaption>
      <h3 className={styles.title}>{knowledge.title}</h3>
    </figcaption>
  </figure>
);

export default Knowledge;
